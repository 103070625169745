/* Estilos para la navegación móvil */
.mobile-navigation {
  list-style-type: none; /* Quita los puntos de la lista */
  padding: 0; /* Quita el padding de la lista */
  margin: 0; /* Quita el margen de la lista */
  display: flex; /* Hace que los elementos de la lista se alineen en fila */
  flex-direction: column; /* Alinea los elementos en columna */
  background-color: #f8f9fa; /* Color de fondo */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para darle profundidad */
}

.mobile-navigation li {
  margin: 8px 0; /* Espaciado entre los elementos de la lista */
}

.mobile-navigation li:first-child {
  margin-top: 16px; /* Espaciado superior para el primer elemento */
}

.mobile-navigation li:last-child {
  margin-bottom: 16px; /* Espaciado inferior para el último elemento */
}

.mobile-navigation button {
  width: 100%; /* Hace que el botón ocupe todo el ancho del contenedor */
  text-align: left; /* Alinea el texto a la izquierda */
  padding: 12px; /* Padding interno del botón */
  color: #000000; /* Color del texto del botón */
  background-color: transparent; /* Fondo transparente */
  border: none; /* Sin borde */
  border-radius: 4px; /* Bordes redondeados */
  transition: background-color 0.3s ease; /* Transición suave para el fondo */
}

.mobile-navigation button:hover {
  background-color: #e2e6ea; /* Fondo al pasar el ratón */
}

.mobile-navigation button:focus {
  outline: none; /* Quita el borde del foco */
  background-color: #d6d8db; /* Fondo al enfocar */
}

